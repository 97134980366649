import { OktaAuthOptions } from "@okta/okta-auth-js";

const CLIENT_ID = process.env.REACT_APP_OKTA_CLIENT_ID || "";
const ISSUER = process.env.REACT_APP_OKTA_ISSUER || "";
const REDIRECT_URI = `${window.location.origin}/login/callback`;

export const oktaAuthConfig: OktaAuthOptions = {
  clientId: CLIENT_ID,
  cookies: {
    path: "/",
    sameSite: "Lax",
    secure: process.env.NODE_ENV !== "development",
    sessionCookie: true,
  },
  devMode: process.env.NODE_ENV === "development",
  issuer: ISSUER,
  pkce: true,
  redirectUri: REDIRECT_URI,
  scopes: ["openid", "profile", "email"],
  storageManager: {
    token: {
      storageType: "cookie",
      useSeparateCookies: true,
      storageKey: "tk",
    },
    transaction: {
      storageType: "cookie",
      useSeparateCookies: true,
      storageKey: "tx",
    },
  },
};

export const loginFlowErrors = {
  sessionTimeOut: "Your session has timed out.",
  incorrectPasswordWarning:
    "Warning: You have X more password attempts before your account is locked.",
  credentialDoNotMatch:
    "The credentials you entered do not match our records. Please try again. The account will be locked after 6 failed attempts.",

  accountLocked:
    "You have exceeded the number of sign in attempts. Please check your email for further instructions.",
  invalidEmail: "Please enter a valid email.",

  incorrectPassCode:
    "This looks like an incorrect passcode. Please verify the passcode and try again.",
  expiredPassCode:
    "Your passcode has expired. Please select Resend Code for a new Code.",

  incorrectKey:
    "This looks like an incorrect key. Please check the key and try again.",
  pushDeclined: "Your Okta push verification was declined. Please re-verify.",
  pushExpired:
    "Your Okta push verification has expired. Please select Resend Push for a new push verification.",

  passDoNotMatch: "Passwords don't match.",
  passCriteriaNotMet: "Password criteria not met.",
  passChangeFailed:
    "Failed to change password. Please enter correct credentials.",

  invalidPhoneNumber: "Please enter a valid Phone Number.",

  emptyPasswordHintText: "This field cannot be left blank.",
  mfaNotEnrolled: "MFA is not enrolled for this email.",
};
