const staticLinks = [
  {
    key: "get-help-link",
    name: "Get Help",
    href: "https://www.workday.com/en-us/workdayresourcecenterfaq.html",
    target: "_blank",
  },
  {
    key: "legal-link",
    name: "Legal",
    href: "https://www.workday.com/en-us/legal/site-terms.html",
    target: "_blank",
  },
  {
    key: "privacy-link",
    name: "Privacy",
    href: "https://www.workday.com/en-us/privacy.html",
    target: "_blank",
  },
];

export default staticLinks;
