import { dubLogoBlue, dubLogoWhite } from "@workday/canvas-kit-react/common";
import { LoadingDots } from "@workday/canvas-kit-react/loading-dots";
import { sanitize } from "dompurify";
import React from "react";

interface LoaderProps {
  theme?: "dark" | "light";
  height?: "auto" | "full" | "screen";
  hideDub?: boolean;
}

function Loader({ theme, height, hideDub }: LoaderProps) {
  let bgColor;
  let dubLogo;

  if (theme === "dark") {
    bgColor = "#0875e1";
    dubLogo = dubLogoWhite;
  } else {
    bgColor = "#FFFFFF";
    dubLogo = dubLogoBlue;
  }

  return (
    <div
      className={`bg-[${bgColor}] h-${height} flex flex-col justify-center items-center gap-[24px]`}
    >
      {!hideDub && (
        <div dangerouslySetInnerHTML={{ __html: sanitize(dubLogo) }} />
      )}
      <LoadingDots />
    </div>
  );
}

Loader.defaultProps = {
  theme: "dark",
  height: "auto",
  hideDub: false,
};

export default Loader;
