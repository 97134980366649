import React from "react";
import { Route, Switch, useHistory } from "react-router-dom";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import { Security, SecureRoute } from "@okta/okta-react";
import Loader from "../components/page-loader";
import config from "../config";
import routes from "../routes";

const { oktaAuthConfig } = config;
const {
  privateRoutes: { pageRoutes: privateRoutes },
  publicRoutes: { pageRoutes: publicRoutes },
} = routes;

const oktaAuth = new OktaAuth(oktaAuthConfig);

function App() {
  const history = useHistory();

  const onAuthRequired = () => {
    history.push("/login");
  };

  const restoreOriginalUri = async (
    _oktaAuth: OktaAuth,
    originalUri: string,
  ) => {
    history.replace(toRelativeUrl(originalUri || "", window.location.origin));
  };

  return (
    <React.Suspense fallback={<Loader height="screen" />}>
      <Security
        oktaAuth={oktaAuth}
        onAuthRequired={onAuthRequired}
        restoreOriginalUri={restoreOriginalUri}
      >
        <Switch>
          {publicRoutes.map((item) => (
            <Route
              key={item.key}
              path={item.path}
              component={item.component}
              exact={item.exact}
            />
          ))}
          {privateRoutes.map((item) => (
            <SecureRoute
              key={item.key}
              path={item.path}
              component={item.component}
              exact={item.exact}
            />
          ))}
        </Switch>
      </Security>
    </React.Suspense>
  );
}

export default App;
