import React from "react";
import { LoginCallback } from "@okta/okta-react";
import Loader from "../../components/page-loader";

const LoginPage = React.lazy(() => import("../../app/public/login-page"));

const routes = [
  {
    key: "login-callback",
    path: "/login/callback",
    component: () => (
      <LoginCallback loadingElement={<Loader height="screen" />} />
    ),
    exact: true,
  },
  {
    key: "login-page",
    path: "/login",
    component: () => <LoginPage />,
    exact: false,
  },
];

export default routes;
