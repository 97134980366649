/*
// QA Tiles
const staticLinks = [
  {
    key: "administrator_guide",
    name: "Administrator Guide",
    target: "_self",
    href: "https://workday-ciam-preview-admin.oktapreview.com/admin/app/workday-ciam-preview_administratorguide_1/instance/0oavofz32k5cmBsNf0h7/",
  },
  {
    key: "community",
    name: "Community",
    target: "_self",
    href: "https://workday-ciam-preview-admin.oktapreview.com/admin/app/drupal_miniorange/instance/0oaqfvpr1sg0KrBxC0h7/",
  },
  {
    key: "community_custom",
    name: "Community - Custom",
    target: "_self",
    href: "https://workday-ciam-preview-admin.oktapreview.com/admin/app/workdayciampreview_communitycustom_1/instance/0oaqh6xxbwsFibrIP0h7/",
  },
  {
    key: "community_anc",
    name: "Community ANC",
    target: "_self",
    href: "https://workday-ciam-preview-admin.oktapreview.com/admin/app/workday-ciam-preview_communityanc_1/instance/0oa16q49306M01tbw0h8/",
  },
  {
    key: "community_perf",
    name: "Community Perf",
    target: "_self",
    href: "https://workday-ciam-preview-admin.oktapreview.com/admin/app/workday-ciam-preview_communityperf_1/instance/0oa1c1z19v3TwWRBY0h8/",
  },
  {
    key: "khoros_sandbox",
    name: "Khoros Sandbox",
    target: "_self",
    href: "https://workday-ciam-preview-admin.oktapreview.com/admin/app/workday-ciam-preview_khorosdev_1/instance/0oa194oz5vbU1wsJN0h8/",
  },
  {
    key: "ourm",
    name: "OURM",
    target: "_self",
    href: "https://workday-ciam-preview-admin.oktapreview.com/admin/app/workday-ciam-preview_ourm_1/instance/0oa17i517vphPqvtx0h8/",
  },
  {
    key: "salesforce_customer_portal",
    name: "Salesforce Customer Portal",
    target: "_self",
    href: "https://workday-ciam-preview-admin.oktapreview.com/admin/app/salesforce_portal/instance/0oaqfvr6c6Xp3hJeX0h7/",
  },
  {
    key: "salesforce_wcp",
    name: "Salesforce WCP",
    target: "_self",
    href: "https://workday-ciam-preview-admin.oktapreview.com/admin/app/salesforce/instance/0oatcoq8lt07oFKIA0h7/",
  },
  {
    key: "salesforce.com",
    name: "Salesforce.com",
    target: "_self",
    href: "https://workday-ciam-preview-admin.oktapreview.com/admin/app/salesforce/instance/0oaqfu8fvfb57vL980h7/",
  },
  {
    key: "workday_channel_partner_center",
    name: "Workday Channel Partner Center",
    target: "_self",
    href: "https://workday-ciam-preview-admin.oktapreview.com/admin/app/salesforce/instance/0oa12aokznjO0JW3H0h8/",
  },
  {
    key: "workday_community_bnc",
    name: "Workday Community BNC",
    target: "_self",
    href: "https://workday-ciam-preview-admin.oktapreview.com/admin/app/workdayciampreview_communitybcn_1/instance/0oaqh7aiqenHA3nOh0h7/",
  },
  {
    key: "workday_customer_center",
    name: "Workday Customer Center",
    target: "_self",
    href: "https://workday-ciam-preview-admin.oktapreview.com/admin/app/salesforce/instance/0oaw87dxhgP1pZI400h7/",
  },
  {
    key: "workday_customer_center_2",
    name: "Workday Customer Center 2",
    target: "_self",
    href: "https://workday-ciam-preview-admin.oktapreview.com/admin/app/workday-ciam-preview_workdaycustomercenter2_1/instance/0oa1elxli4zQfduX80h8/",
  },
  {
    key: "workday_customer_center_3",
    name: "Workday Customer Center 3",
    target: "_self",
    href: "https://workday-ciam-preview-admin.oktapreview.com/admin/app/workday-ciam-preview_workdaycustomercenter3_1/instance/0oa1elxc2xzd4Dt7R0h8/",
  },
  {
    key: "workday_partner_center",
    name: "Workday Partner Center",
    target: "_self",
    href: "https://workday-ciam-preview-admin.oktapreview.com/admin/app/workday-ciam-preview_ourm_1/instance/0oa17i517vphPqvtx0h8/",
  },
  {
    key: "workday_psa_impl_11",
    name: "Workday PSA (Impl 11)",
    target: "_self",
    href: "https://workday-ciam-preview-admin.oktapreview.com/admin/app/workday/instance/0oaqf9h0ls911YWQ70h7/",
  },
];
*/

const staticLinks = [
  {
    key: "administrator_guide",
    name: "Administrator Guide",
    description: null,
    target: "_self",
    href: "#",
  },
  {
    key: "community_khoros",
    name: "Community - Khoros",
    description: null,
    target: "_self",
    href: "#",
  },
  {
    key: "ebooks",
    name: "eBooks",
    description:
      "View the electronic course manuals for your product training, hosted on VitalSource.",
    target: "_self",
    href: "#",
  },
  {
    key: "workday_community",
    name: "Workday Community",
    description:
      "Connect and collaborate with customers like you in the Workday Community.",
    target: "_self",
    href: "#",
  },
  {
    key: "workday_customer_center",
    name: "Workday Customer Center",
    description:
      "The place for all of your support and tenant management needs. Find reports, dashboards, and a content library to help guide you in your day to day.",
    target: "_self",
    href: "#",
  },
  {
    key: "workday_earning",
    name: "Workday Learning",
    description:
      "Engage with product training from the Workday Learning Center.",
    target: "_self",
    href: "#",
  },
];

export default staticLinks;
