import React from "react";

const HomePage = React.lazy(() => import("../../app/private/home-page"));
const LogoutPage = React.lazy(() => import("../../app/private/logout-page"));

const routes = [
  {
    key: "home-page",
    path: "/",
    component: () => <HomePage />,
    exact: true,
  },
  {
    key: "logout-page",
    path: "/logout",
    component: () => <LogoutPage />,
    exact: true,
  },
  {
    key: "404-pages",
    path: "*",
    component: () => <HomePage />,
    exact: false,
  },
];

export default routes;
