import React from "react";

const AuthenticationForm = React.lazy(
  () => import("../../components/login/authentication-form"),
);
const MFARequiredForm = React.lazy(
  () => import("../../components/login/mfa-required-form"),
);
const MFAChallengeForm = React.lazy(
  () => import("../../components/login/mfa-challenge-form"),
);
const AuthenticationSuccess = React.lazy(
  () => import("../../components/login/authentication-success"),
);

const routes = [
  {
    key: "login-authenticate",
    path: "/authenticate",
    component: () => <AuthenticationForm />,
    exact: true,
  },
  {
    key: "login-mfa-required",
    path: "/mfa-required",
    component: () => <MFARequiredForm />,
    exact: true,
  },
  {
    key: "login-mfa-challenge",
    path: "/mfa-challenge",
    component: () => <MFAChallengeForm />,
    exact: true,
  },
  {
    key: "login-success",
    path: "/authentication-success",
    component: () => <AuthenticationSuccess />,
    exact: true,
  },
];

export default routes;
